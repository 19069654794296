
.container {
    margin-top: 32px;
}

.summary-tile {
    font-size: 4em;
    border-right: 1px solid #ddd;
}

.summary-tile.last {
    border-right: none;
}

.text-style{
    padding-top: 0.2%;
}
