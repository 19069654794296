.text{
    color: firebrick;
}

.contact-item {
    padding-top: 12px;
    padding-bottom: 12px;
}

.item.contact {
    font-size: .9em;
}

.light-text {
    color: #777;
}

.body{
    background-color: #FAFAFA;
}

.black {
    color: black;
}

.red {
    color: firebrick;
}

a {
    text-decoration: none;
    color: black;
}

.fixed {
    position: fixed;
    bottom: 15px;
    right: 0px;
    width: 50px;
    
}

.item {
    margin-left: 10px;
}